import React from 'react';
import './Header.css';

// TODO image size 320px
function Title() {
    return (
        <header className="Header">
            <div className="Header-Face"></div>
            <div className="Header-Description">
                <h1>Hi, ich bin Matthias.</h1>
                <p>Schön, dass du da bist.</p>
            </div>
        </header>
    );
}

export default Title;
