import React from 'react';
import './Main.css';

import XingImage from './XNG_Sharebutton_v01-7d06f36109c803c7a79f5a5c597f1fb8c8ff4d310d74ee8f6b31a56b6d6c3eea.png';
import LinkedinImage from './LI-In-Bug.png';

const DottedLine = () => (
    <hr
        style={{
            textAlign: 'center',
            borderWidth: '0px',
            fontSize: '28px',
            marginTop: '30px',
        }}
    />
);


function Main() {
    return (
        <div className="Main-Box">
            <div className="Main-Container">

                <section>
                    <h2>Über mich</h2>
                    <article className="Main-Article-Step">
                        <p>
                            Seit 2016 arbeite ich als Software Engineer im Bereich Webentwicklung, seit 2017 im schönen
                            München. Davor habe ich viele Jahre Software Projekte in einer Agentur für Kunden umgesetzt.
                        </p>
                        <p>Durch diese vielfältigen Erfahrungen bringe ich ein breites Spektrum an Wissen mit.</p>
                    </article>
                    <DottedLine />


                    <h2>Das habe ich zu bieten</h2>
                    <article className="Main-Article-Step">
                        <p>
                            <strong>Tech-Stack:</strong> PHP (Symfony, Doctrine), Node JS (Frameworks: Express JS, Nest JS) , TypeScript, React JS,
                            PostgreSQL, HTML, CSS (mit SCSS), Kubernetes (mit Docker), IDE (IntelliJ IDEA - PHP Storm, Visual Studio
                            Code), Linux (Debian), …
                        </p>
                    </article>
                    <article className="Main-Article-Step">
                        <strong>Angewandte Konzepte:</strong> API Design, Clean Code, Clean Architecture, Unit Testing, Domain-Driven Design, Design Patterns,
                        Event Sourcing, Microservices, Micro Frontend, CI / CD, …
                    </article>
                    <DottedLine />


                    <h2>Werdegang</h2>
                    <article className="Main-Article-Step">
                        <h5>Oktober 2020 bis heute</h5>
                        <h3>Inhouse Consultant</h3>
                        <h4>BIG.Cube GmbH</h4>
                    </article>

                    <article className="Main-Article-Step">
                        <h5>Mai 2017 - Oktober 2020</h5>
                        <h3>Software Engineer</h3>
                        <h4>Jochen Schweizer mydays Holding GmbH</h4>
                        <div className="JobDescription">
                            Entwicklung einer europäischen Plattform für Gutscheine und Tickets in den Bereichen Front-
                            und Backend und Cloud.</div>
                        <div className="JustifyItem">
                            - PHP, Node JS, React JS, Kubernetes, Clean Code, Clean Architecture, Domain-Driven Design,
                            Microservices, Micro Frontend, Nginx, PostgreSQL, MS SQL, CI / CD, DNS, …
                        </div>
                    </article>

                    <article className="Main-Article-Step">
                        <h5>Juni 2016 - Februar 2017</h5>
                        <h3>PHP-Entwickler</h3>
                        <h4>Insecia GmbH</h4>
                        <div className="JobDescription">
                            Entwicklung einer Cloud Software für Content-Management-System, Shop und Terminbuchung.</div>
                        <div className="JustifyItem">
                            - PHP, JQuery, MySQL, MongoDB
                        </div>
                    </article>
                    <DottedLine />


                    <h2>Dafür brenne ich</h2>
                    <article className="Main-Article-Step">
                        <p>
                            Technologischer Fortschritt, Comics, Wandern, Fahrradfahren, Reisen, Kochen, Segeln, München.
                        </p>
                    </article>
                    <DottedLine />


                    <h2>Kontakt</h2>
                    <article className="Main-Article-Step">
                        <p>
                            Ich freue mich über einen Austausch.
                        </p>
                        <p className="ExternLinkIcons">
                            <a
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                href="https://www.xing.com/profile/Matthias_Gartemann/cv">
                                <img
                                    className="ExternLinkIcon"
                                    src={XingImage}
                                    alt="Link to Xing"
                                />
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                                href="https://www.linkedin.com/in/matthias-gartemann-8504401a4/">
                                <img
                                    className="ExternLinkIcon"
                                    src={LinkedinImage}
                                    alt="Link to Linkedin"
                                />
                            </a>
                        </p>
                    </article>


                </section>

            </div>
        </div>
    );
}

export default Main;
