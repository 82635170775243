import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="Footer-Box">
            <p className="PaddingButton15">
                © 2024 Matthias Gartemann // Software Engineer // Impressum // Kontakt
            </p>
            <p className="PaddingButton15">
                Made with <span className="Love">♥</span> in Munich
            </p>
        </footer>
    );
}

export default Footer;